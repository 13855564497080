import { useContext } from "react";
import {
  HStack,
  Image,
  Drawer,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CustomerContext } from "../CustomerContext";
import { ChevronDownIcon } from "@chakra-ui/icons";

const MobileNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isCustomer, setIsCustomer } = useContext(CustomerContext);
  return (
    <HStack bgColor={"#FFF"} justifyContent={"space-between"} p={6} w="full">
      <Link to="/">
        <Image src="/images/mainLogo.png" alt="Logo" h={8} />
      </Link>
      <Image w={8} h={8} src="/images/hamburger.svg" onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerContent>
          <DrawerBody
            display={"flex"}
            flexDir={"column"}
            alignItems={"start"}
            mt={6}
            justifyContent={"space-between"}
          >
            <VStack gap={2} alignItems={"start"} w="full">
              <Link to={"/"} style={{ width: "100%" }} onClick={onClose}>
                <HStack _hover={{ bg: "athensGray" }} p={2}>
                  <Image src="/images/home-icon.svg" alt="Logo" h={6} />
                  <Text>Home</Text>
                </HStack>
              </Link>
              <Accordion w="full" allowToggle>
                <AccordionItem>
                  <AccordionButton p={2} _hover={{ bg: "athensGray" }}>
                    <HStack gap={"10px"}>
                      <Image src="/images/info-icon.svg" alt="Logo" h={6} />
                      <Text>About</Text>
                      <AccordionIcon m={0} />
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel onClick={onClose} pb={0} >
                    <VStack alignItems={"start"}>
                      <Link to={"/about"}>
                        <Text>About Us</Text>
                      </Link>
                      <Link to={"/aim"}>
                        <Text>Aim & Vision</Text>
                      </Link>
                      <Link to={"/values"}>
                        <Text>Key Values</Text>
                      </Link>
                      <Link to={"/work"}>
                        <Text>Work for Social Cause</Text>
                      </Link>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle w="full">
                <AccordionItem>
                  <AccordionButton p={2} _hover={{ bg: "athensGray" }}>
                    <HStack gap={"10px"}>
                      <Image src="/images/policy-icon.svg" alt="Logo" h={6} />
                      <Text>Policy</Text>
                      <AccordionIcon m={0} />
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel onClick={onClose} >
                    <VStack alignItems={"start"}>
                      <Link to={"/privacy"}>
                        <Text>Privacy Policy</Text>
                      </Link>
                      <Link to={"/refund"}>
                        <Text>Refund & Cancellation Policy</Text>
                      </Link>
                      <Link to={"/return"}>
                        <Text>Return Policy</Text>
                      </Link>
                      <Link to={"/shipping"}>
                        <Text>Shipping Policy</Text>
                      </Link>
                      <Link to={"/terms"}>
                        <Text>Terms of Use</Text>
                      </Link>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </VStack>
            <Button
              bg={"scarlet"}
              color={"#FFF"}
              cursor={"pointer"}
              fontSize={"14px"}
              fontWeight={"600"}
              borderRadius={"10px"}
              onClick={() => {
                setIsCustomer(!isCustomer);
                onClose();
              }}
              _hover={{ bg: "scarlet" }}
              w="full"
            >
              {isCustomer ? "Become a Seller" : "Customer Site"}
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
};

export default MobileNavbar;
