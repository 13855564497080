import React from "react";
import { VStack, Heading, Text, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <VStack px={{ base: 10, tablet: "104px" }} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Terms of Use</title>
      </Helmet>
      <Heading fontSize={{ base: "24px", tablet: "36px" }} fontWeight={"700"}>
        Terms of Use
      </Heading>
      <Image mt={20} src="/images/terms-hero.svg" alt="hero" />
      <VStack
        px={{ base: 6, tablet: 16 }}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
        Please read the Terms of Use set out below carefully before ordering any Goods or Services from our app or website. By ordering any Goods or Services you agree to be bound by these Terms of Use.
        </Text>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 1: Introduction:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"} >
          The domain name www.cakelaya.com (from now on referred to as "Website") is owned by ZESTEASE FOODTECH PRIVATE LIMITED a company incorporated under the Companies Act, 1956.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 2: Definitions:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            2.1. “Agreement” is a reference to these Terms of Use, as well as any order, form, and payment instructions provided to you.
            <br />
            2.2. “Privacy Policy” means the Policy displayed on our website which details how we collect and store your data.
            <br />
            2.3. “you”, “your” and “yours” are references to you, the person accessing our app or website and ordering any Goods or Services.
            <br />
            2.4. “we”, “us”, “our”, and ”cakelaya” are references to the cakelaya.
            <br />
            2.5. “Goods”, and “Items” is a references to any Goods which we may offer for sale from our app or website from time to time.
            <br />
            2.6. “Service” or “Services” is a reference to any Service which we may supply and which you may request via our app or website.
            <br />
            2.7. “Partner” is a third party, which has agreed to co-operate with us to prepare and/or deliver the Goods or Services.
            <br />
            2.8. “Food Delivery” is a reference to perishable goods and to any form of delivery service, both of which are provided by our partners and for which our partners take full responsibility.
            <br />
            2.9. “Website” is a reference to our website www.cakelaya.com or our mobile applications on which we offer our Goods or Services.
            <br />
          </Text>

        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 3: Ordering
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            3.1.  Any contract for the supply of Food Delivery from our app or website is between you and our partners. You agree to take particular care when providing us with your details and warrant that these details are accurate and complete at the time of ordering. You also warrant that the credit or debit card details that you provide are for your own credit or debit card and that you have sufficient funds to make the payment.
            <br />
            3.2. Food Delivery, Goods and Services purchased from our app or website are intended for your use only and you warrant that any Goods purchased by you are not for resale and that you are acting as principal only and not as agent for another party when receiving the Services.
            <br />
            3.3. We will take all reasonable care, in so far as it is in our power to do so, to keep the details of your order and payment secure, but in the absence of negligence on our part we cannot be held liable for any loss you may suffer if a third party procures unauthorized access to any data you provide when accessing or ordering from the app or website.
            <br />
            3.4. Any order that you place with us is subject to product availability, delivery capacity and acceptance by us and the partner. When you place your order online, we will send you an email to confirm that we have received it. This email confirmation will be produced automatically so that you have confirmation of your order details. The fact that you receive an automatic confirmation does not necessarily mean that either we or the partner will be able to fill your order. Once we have sent the confirmation email we will check availability and delivery capacity.
            <br />
            3.5. If the ordered Food and Delivery capacity is available, the partner will accept the contract and confirm it to cakelaya. If the details of the order are correct, the contract for the Food Delivery, Goods or Services will be confirmed by email or text message.
            <br />
            3.6. If the Food Delivery and/or Goods are not available or if there is no delivery capacity, we will also let you know by email.
            <br />
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 4: Prices and Payment
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            4.1. You agree to take particular care when providing us with your details and warrant that these details are accurate and complete at the time of ordering. You also warrant that the credit or debit card details that you provide are for your own credit or debit card and that you have sufficient funds to make the payment.
            <br />
            4.2. All prices listed on the app or website are correct at the time of publication and have been based on input received from the partner; while we give great care to keep them up to date, the final price charged to you can change at the time of delivery based on the latest menu and prices. We also reserve the right to alter the Goods or Services available for sale on the app or website.
            <br />
            4.3. All prices for delivery by cakelaya or a third-party provider assigned by cakelaya are correct at the time of publication. However, we reserve the right to alter these in the future.
            <br />
            4.4. The total price for Food Delivery, Goods or Services ordered, delivery charges and other charges will be displayed on the app or website when you place your order. Full payment must be made for all Goods dispatched and Services provided. Payment has to be made online, e.g., via UPI, Net Banking, Credit or Debit card.
            <br />
            4.5. In case of online payment, you must pay for your order before it is delivered. Our app or website is integrated with 3rd party payment gateways. We do not store any card or banking details on our app or website. For privacy policies and terms and conditions, please refer to the relevant payment gateway’s app or website.
            <br />
            4.6. cakelaya never asks for any of your personal bank details. Please do not share your personal bank details like Card Number/PIN/CVV/OTP/Card Expiry Date/UPI PIN, over Phone/Mail/SMS to anyone.
            <br />
          </Text>
        </VStack>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 5: Delivery
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            5.1. Delivery periods quoted at the time of ordering are approximate only and may vary. Goods will be delivered to the address designated by you at the time of ordering.
            <br />
            5.2. Since delivery is done by delivery partners, it is the partner's sole responsibility to provide Food Delivery promptly.
            <br />
            5.3. No responsibility is taken for late delivery by cakelaya.
            <br />
            5.4. We and the delivery partners will make every effort to deliver within the time stated. However, we will not be liable for any loss caused to you by ordering late.
            <br />
            5.5. In case of a late delivery, the delivery charge will neither be voided nor refunded by cakelaya.
            <br />
            5.6. All risks in the Goods and the Food Delivery shall pass to you upon delivery.
            <br />
            5.7. If you fail to accept delivery of Goods at the time they are ready for delivery, or we are unable to deliver at the nominated time due to your failure to provide appropriate instructions, then such Goods shall be deemed to have been delivered to you and all risk and responsibility about such Goods shall pass to you.
            <br />
            5.8. Partners, who will prepare your order, aim to deliver the product to you at the place and time of delivery requested by you in your order. However, we shall not be liable to you for any losses, liabilities, costs, damages, charges or expenses arising out of late delivery.
            <br />
            5.9. Please note that it might not be possible for us to deliver to some locations. If this is the case, we or our partners will inform you using the contact details that you provide to us when you make your order and arrange for cancellation of the order or delivery to an alternative delivery address.
            <br />
            5.10. The delivery of the items ordered will be attempted only ONCE at the address as provided. In the event the delivery is not executed due to any reason whatsoever, the order shall be considered complete, and re-delivery or a refund will not be an option. The order will be deemed executed and delivered in the following events: Wrong address; Recipient not available at premises; Premises locked; Unreachable or incorrect phone number of the recipient; The recipient refused the products; Delivered the product at the gate/reception/neighbourhood of the premises Restricted entry to premises.
          </Text>
        </VStack>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 6: Cancellation:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            Please refer to the cancellation policy on our website.
          </Text>
        </VStack>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 7: Linked Sites:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
          There may be several links on our app or website to third-party apps or websites which may be of interest to you. We do not represent the quality of the Goods or Services provided by such third parties nor do we have any control over the content or availability of such sites. We cannot accept any responsibility for the content of third-party apps or websites or the Services or Goods that they may provide to you.
          </Text>
        </VStack>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 8: Complaints:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
          We take complaints very seriously. All complaints should be addressed to our official email ID, and we assure to assist you. Kindly note that the images displayed on the app or website are indicative in nature. Actual product may vary in shape or design as per the availability.
          </Text>
        </VStack>

        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 9: Limitation of Liability:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            9.1. Great care has been taken to ensure that the information available on our app or website is correct and error-free. We apologize for any errors or omissions that may have occurred. Therefore, we do not make any warranty whatsoever, whether express or implied, relating to its accuracy.
            <br />
            9.2. By accepting these Terms of Use, you agree to relieve us from any liability whatsoever arising from your use of information from any third party, your use of any third-party app or website, or your consumption of any Food or Beverages from a partner.
            <br />
            9.3. We disclaim any liability to you for the supply of the Food Delivery, Goods and Services to the fullest extent permissible under applicable law. This does not affect your statutory rights as a consumer. If we are found liable for any loss or damage to you, such liability is limited to the amount you have paid for the relevant Goods or Services. We cannot accept any liability for any loss, damage or expense, including any direct or indirect loss.
            <br />
            9.4. We do not accept any liability for any delays, failures, errors omissions or loss of information, viruses or other contamination or destructive properties transmitted to you or your computer system via our app or website.
            <br />
            9.5. We shall not be held liable for any failure or delay in performing Services or delivering Goods arising owing to reasons beyond our control.
            <br />
            9.6. The products sold by us are provided for private domestic and consumer use only. Accordingly, we do not accept liability for any indirect loss, consequential loss, loss of data, loss of income or profit, loss of damage to property and/or loss from claims of third parties arising out of the use of the app or website or for any products or Services purchased from us.
            <br />
            9.7. We have taken all reasonable steps to prevent Internet fraud and ensure any data collected from you is stored as securely and safely as possible. However, we cannot be held liable in the extremely unlikely event of a breach in our secure computer servers or those of third parties.
            <br />
            9.8. Offers are subject to cakelaya discretion and may be withdrawn at any time and without notice.
            <br />
          </Text>

        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"700"}
            color={"black"}
          >
            Section 10: General:
          </Heading>
          <Text color={"droveGray"} align={"justify"} fontWeight={"400"}>
            10.1. All prices are in Indian Rupees and can be converted to your local currency at the time of payment, depending on the payment gateway provider.
            <br />
            10.2. We may subcontract any part or parts of the Services or Goods that we provide to you from time to time without your consent or any requirement to notify you.
            <br />
            10.3. We may alter or vary the Terms of Use at any time without notice to you.
            <br />
            10.4. Please do not use or launch any automated system or program in connection with our app or website.
            <br />
            10.5. Please do not collect or harvest any personally identifiable information from the app or website, use communication systems provided by the app or website for any commercial solicitation purposes, solicit for any reason whatsoever any users of the app or website concerning their submissions to the app or website, or publish or distribute any vouchers or codes in connection with the app or website, or scrape or hack the app or website.
            <br />
            10.6. The Terms of Use together with the Privacy Policy, any order form and payment instructions constitute the entire agreement between you and us. No other Terms whether expressed or implied shall form part of this Agreement. In the event of any conflict between these Terms of Use and any other term or provision on the app or website, these Terms and Conditions shall prevail.
            <br />
            10.7. If any Term of our Agreement shall be deemed invalid, illegal or unenforceable, the parties hereby agree that such term or condition shall be deemed to be deleted and the remainder of the Agreement shall continue in force without such Term.
            <br />
            10.8. These Terms of Use and our Agreement shall be governed by and construed by the laws of India. The parties hereto submit to the exclusive jurisdiction of the courts of India. The place of jurisdiction shall be exclusively in Delhi.
            <br />
            10.9. No delay or failure on our part to enforce our rights or remedies under the Agreement shall constitute a waiver on our part of such rights or remedies unless such waiver is confirmed in writing.
            <br />
            10.10. These Terms of Use and a contract (and all non-contractual obligations arising out of or connected to them) shall be governed and construed by Indian Laws. Both we and you hereby submit to the non-exclusive jurisdiction of the Indian Courts. All dealings, correspondence and contacts between us shall be made or conducted in the English language.
            <br />
            10.11. While sharing my phone number, email ID and name during the registration or payment process, I understand that cakelaya can send transactional and promotional SMS messages to my registered phone number. I also understand that cakelaya can send transactional and promotional email messages to my registered email ID as well.
            <br />
            10.12. As we will be processing the order post your confirmation, it won't be possible to modify or cancel the order.
            <br />
            10.13. I understand that cakelaya can send transactional and promotional SMS, Calls, Whatsapp, RCS and emails on my registered email ID & and number.
            <br />
            10.14. In any case, if the order is placed by any individual unethically from cakelaya this will be considered part of a fraud. The company has the right to cooperate with competent law enforcement agencies and to ask them to take appropriate action whenever such phenomena occur. Additionally, the company will notify the police or other competent authority.
            <br />
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Terms;
