import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    colors: {
        scarlet: '#FF1616',
        black: '#000000',
        saltBox: '#6D6875',
        mineShaft: '#303030',
        chablis: '#FFF5F5',
        gray: '#8F8F8F',
        fairPink: '#FFEAEA',
        emperor: '#515151',
        vulcan: '#0E0F1D',
        cocoaBean: '#4A1D1F',
        pippin: '#FFDEDE',
        mineShaft2: '#272727',
        doveGray: '#626262',
        athensGray: '#F2F2F3'
    },
    fonts: {
        body: 'Poppins',
        heading: 'Poppins'
    },
    fontSizes: {
        '2xs': '0.625rem', // 10px
        xs: '0.75rem', // 12px
        sm: '0.875rem', // 14px
        md: '1rem', // 16px
        lg: '1.125rem', // 18px         | H6
        xl: '1.3125rem', // 21px        | H5
        '2xl': '1.5rem', // 24px        | H4
        '3xl': '1.75rem', // 28px       | H3
        '3.5xl': '1.875rem', // 30px    | Not for desktop / Mobile H2
        '4xl': '2rem', // 32px          | H2 / Mobile H1
        '5xl': '2.25rem', // 36px       | H1
    },
    components: {
        Accordion: {
            baseStyle: {
                button: {
                    _focus: {
                        bg: 'none'
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                },
                container: {
                    border: 'none'
                },
                icon: {
                    ml: 4,
                }
            }
        },
        Menu: {
            baseStyle: {
                item: {
                    borderBottom: '1px solid #E0E0E0',
                    py: 2.5,
                },
                list: {
                    p: 0,
                    border: 0,
                    borderRadius: 0,
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
                }
            }
        }
    },
    breakpoints: {
        sm: '360px',
        mob: '480px',
        md: '768px',
        tablet: '992px',
        lg: '1200px',
        xl: '1366px',
        '2xl': '1920px',
        '3xl': '2560px',
    }
})

export default theme;