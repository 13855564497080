import React, { useEffect, useState } from 'react';
import { Box, Button, Text, Link } from '@chakra-ui/react';

const Shop = () => {
    const [isContainerVisible, setContainerVisible] = useState(false);

    const getQueryParam = (param) => {
        const pathParts = window.location.pathname.split('/');
        if (param === 'shopId' && pathParts[1] === 'shop') {
          return pathParts[2];
        }
        return null;
    };
    
    useEffect(() => {
        const shopId = getQueryParam('shopId');
        const deepLink = `cakelaya://shop/${shopId}`;
        const openAppButton = document.getElementById('openApp');
    
        if (openAppButton) {
          openAppButton.href = deepLink;
        }
    
        // Try to open the app automatically
        const appRedirectTimeout = setTimeout(() => {
          window.location.href = deepLink;
        }, 100);
    
        // If the app doesn't open within 2 seconds, show the buttons
        const showContainerTimeout = setTimeout(() => {
          setContainerVisible(true);
        }, 2000);
    
        return () => {
          clearTimeout(appRedirectTimeout);
          clearTimeout(showContainerTimeout);
        };
    }, []);
    

    return (
        <Box textAlign="center" p={4}>
            <Link id="openApp" style={{ display: 'none' }}>
                Open App
            </Link>
            <Box
                className="container"
                opacity={isContainerVisible ? 1 : 0}
                transition="opacity 0.5s"
                visibility={isContainerVisible ? 'visible' : 'hidden'}
            >
                <Text fontSize="lg" mb={4}>
                If the app doesn’t open, click the button below:
                </Text>
                <Link href="#" id="openApp">
                <Button colorScheme="blue" size="lg">
                    Open App
                </Button>
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.cakelaya.app">Download App</Link>
            </Box>
        </Box>
    );
}

export default Shop;
