import React from "react";
import { VStack, Flex, Text, Image, Heading } from "@chakra-ui/react";

const Card = ({ img, title, desc, isCustomer }) => {
  return (
    <VStack>
      <Flex
        bg={"chablis"}
        borderRadius={"26.39px"}
        mb={{base: "-75px", tablet: -28}}
        p={{base: 2, tablet: 4}}
        zIndex={1}
        boxShadow={"0px 2px 14px rgba(0, 0, 0, 0.2)"}
      >
        <Image src={img} h={{base: "134px", tablet: "227px"}}  />
      </Flex>
      <VStack
        h={{ base: "170px", tablet: "288px" }}
        w={{ base: "226px", tablet: "382px" }}
        pb={{base: 6, tablet: 10}}
        justifyContent={"end"}
        borderRadius={"24px"}
        boxShadow={"0px 2px 14px rgba(0, 0, 0, 0.2)"}
      >
        <VStack>
          <Heading fontSize={{base: "16px", tablet: "32px"}} color={"mineShaft"} fontWeight={"600"}>
            {title}
          </Heading>
          <Text
            fontSize={{base: isCustomer ? "12px" : "10px", tablet: isCustomer ? "20px" : "16px"}}
            fontWeight={"400"}
            color={"gray"}
          >
            {desc}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Card;
