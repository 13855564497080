import React from "react";
import { VStack, Image, Heading, Text, useMediaQuery,Flex } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    quote: "The eggless cakes here are really good. Had ordered a kit kat cake which was really good. Surely worth a try.",
    name: "Darren Dunlap",
    position: "CEO & Founder at Flex.co",
    image: "/images/user-photo.png",
  },
  {
    quote: "Absolutely loved the experience, great cakes and great service, Surely worth a try.",
    name: "Jane Doe",
    position: "Marketing Head at ABC Corp",
    image: "/images/user-photo.png",
  },
  {
    quote: "Their cakes are a piece of art! Will definitely order again, Surely worth a try.",
    name: "John Smith",
    position: "CFO at XYZ Ltd",
    image: "/images/user-photo.png",
  },
];

const Slides = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <VStack py={{ base: 16, tablet: 32 }} w={{base:"full",tablet:"80%"}} height="100%">
      <Text
        px={2}
        py={1}
        bg={"pippin"}
        color={"cocoaBean"}
        borderRadius={"36px"}
        fontSize={{ base: "12px", tablet: "14px" }}
        fontWeight={"400"}
      >
        Our Testimonials
      </Text>
      <Slider {...settings} style={{ width: '100%', height: '100%' }}>
        {testimonials.map((testimonial, index) => (
          <VStack key={index} py={4} px={14}>
            <Heading
              _before={{
                content: "''",
                bgImg: "/images/inverted-comma-2.svg",
                bgPos: "center",
                bgSize: isMobile ? "30%" : "100px", 
                bgRepeat: "no-repeat",
                position: "absolute",
                w: isMobile ? "30%" : "100px",
                h: isMobile ? "30%" : "100px",
                left: isMobile ? "5%" : "-5%",
                top: isMobile ? "-25px" : "-40px", 
                zIndex: -1,
                transform: isMobile ? "translateY(0)" : "none" 
              }}
              _after={{
                content: "''",
                bgImg: "/images/inverted-comma-1.svg",
                bgPos: "center",
                bgSize: isMobile ? "30%" : "100px",
                bgRepeat: "no-repeat",
                position: "absolute",
                w: isMobile ? "30%" : "100px", 
                h: isMobile ? "30%" : "100px", 
                bottom: isMobile ? "-10px" : "-20%", 
                right: isMobile ? "5%" : "-5%", 
                zIndex: -1,
                transform: isMobile ? "translateY(0)" : "none" 
              }}
              mt={4}
              textAlign={"center"}
              fontWeight={"600"}
              fontSize={{ base: "16px", tablet: "48px" }}
              position={"relative"}
            >
              {testimonial.quote}
            </Heading>
            <Flex
              direction="column"
              align="center"
              justify="center"
              mt={14}
            >
              <Image src={testimonial.image} borderRadius="50%" />
              <Heading
                mt={4}
                color="cocoaBean"
                fontWeight="600"
                fontSize={{ base: "12px", tablet: "2xl" }}
                textAlign="center"
              >
                {testimonial.name}
              </Heading>
              <Text
                mt={2}
                color="mineShaft2"
                fontSize={{ base: "10px", tablet: "lg" }}
                fontWeight="400"
                textAlign="center"
              >
                {testimonial.position}
              </Text>
            </Flex>
          </VStack>
        ))}
      </Slider>
    </VStack>
  );
};

export default Slides;
