import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <VStack px={{base: 10, tablet: "104px"}} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Privacy Policy</title>
      </Helmet>
      <Heading fontSize={{base: "24px", tablet: "36px"}} fontWeight={"700"}>
        Privacy Policy
      </Heading>
      <Image mt={20} src="/images/privacy-hero.svg" alt="hero" />
      <VStack
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Updates Notification
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
            Our Privacy Policy may change without prior notice. Please check
            periodically for updates. Your use of our app and website indicates
            acceptance of these terms.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Information Collection
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
            When registering or ordering on our app or website, we may collect
            your phone number, name, email ID, date of birth, gender, and
            delivery address.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Data Usage
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
            We use collected information for personalization, improving user
            experience, customer service, contests, transactions, feedback, and
            promotional messages via registered phone numbers/email IDs.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Security Measures
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
            Our app and website undergo regular security scans and employ SSL
            encryption. Personal data is accessible only to authorised personnel
            and not stored on our servers during transactions.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Third-Party Disclosure and Links
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
            We do not trade or sell Personally Identifiable Information, nor do
            we feature third-party products or services on our platform.
          </Text>
        </VStack>
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}} align={"justify"}>
          For more information, you may write to our customer support team.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Privacy;
