import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <VStack mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - About Us Policy</title>
      </Helmet>
      <VStack
        h={{base: "300px", tablet: "602px"}}
        w="full"
        mb={16}
        position={"relative"}
        _before={{
          content: "''",
          position: "absolute",
          w: "full",
          h: "full",
          bg: "rgba(255,22,22,0.5)",
        }}
      >
        <Heading
          position={"absolute"}
          color={"#FFF"}
          fontSize={{base: "24px", tablet: "36px"}}
          fontWeight={{base: 500, tablet: 600}}
          top={"46%"}
        >
          About Us
        </Heading>
        <Image w="full" h="full" src="/images/about-banner.png" />
      </VStack>
      <VStack
        mx={{base: 10, tablet: "104px"}}
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
        fontSize={{base: '16px', tablet: "22px"}}
      >
        <Text color={"doveGray"} align={"justify"}>
          We're cakelaya, a team of innovative thinkers driven by a shared
          vision to revolutionise the way people order food.
          Here's a glimpse into our story.
        </Text>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: '16px', tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Identifying a Need
          </Heading>
          <Text color={"doveGray"} align={"justify"}>
            We've ourselves experienced the struggle of finding affordable and
            delicious food. Witnessing
            consumers trekking long distances – 5 to 6 kilometres – just to
            get good food highlighted a glaring problem.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: '16px', tablet: "22px"}} fontWeight={"700"} color={"black"}>
            A Community-Centric Solution
          </Heading>
          <Text color={"doveGray"} align={"justify"}>
          Determined to alleviate this common headache, we envisioned a solution: cakelaya, a startup dedicated to delivering food items right to consumers' doorsteps, eliminating the need for lengthy travels and overpriced purchases.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: '16px', tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Convenience at Your Fingertips
          </Heading>
          <Text color={"doveGray"} align={"justify"}>
          With cakelaya, consumers can simply sit back, relax, and enjoy their day. Our seamless delivery service ensures that customers are stress-free and enjoyable.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: '16px', tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Our Promise
          </Heading>
          <Text color={"doveGray"} align={"justify"}>
          We're committed to providing not just food items, but memorable experiences. 
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: '16px', tablet: "22px"}} fontWeight={"700"} color={"black"}>
            Join the Celebration
          </Heading>
          <Text color={"doveGray"} align={"justify"}>
          Join us on this exciting journey as we redefine the way consumers satisfy their cravings. Let cakelaya be your go-to destination for all things delicious and delightful, making every day truly special.
          </Text>
        </VStack>
        <Text color={"doveGray"} align={"justify"}>
          We're not just delivering cakes – we're delivering happiness, one
          slice at a time.
        </Text>
      </VStack>
    </VStack>
  );
};

export default About;
